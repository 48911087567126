import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';
import { _x } from '@wordpress/i18n';

domReady(() => {
  const servicesCarousels = document.querySelectorAll(
    '.services-carousel__carousel-wrapper'
  );

  if (0 < servicesCarousels.length) {
    servicesCarousels.forEach((serviceCarousel) => {
      const slides = serviceCarousel.querySelectorAll('.swiper-slide');
      const args = {
        slidesPerView: 1.2,
        spaceBetween: 12,
        centerInsufficientSlides: true,
        navigation: {
          prevEl: serviceCarousel.querySelector('.swiper-button-prev'),
          nextEl: serviceCarousel.querySelector('.swiper-button-next'),
        },
        pagination: {
          el: serviceCarousel.querySelector('.swiper-pagination'),
          type: 'fraction',
          formatFractionCurrent: (number) => {
            if (number < 10) {
              return '0' + number;
            }
            return number;
          },
          formatFractionTotal: (number) => {
            if (number < 10) {
              return '0' + number;
            }
            return number;
          },
        },
        breakpoints: {
          600: {
            slidesPerView: 2.2,
            spaceBetween: 12,
          },
          920: {
            slidesPerView: 3.3,
            spaceBetween: 24,
          },
          1200: {
            slidesPerView: 4.1,
            spaceBetween: 24,
          },
        },
        a11y: {
          firstSlideMessage: _x(
            'This is the first slide',
            'carousel first slide label',
            'vtx'
          ),
          lastSlideMessage: _x(
            'This is the last slide',
            'carousel first slide label',
            'vtx'
          ),
          paginationBulletMessage: _x(
            'Go to slide {{index}}',
            'carousel navigation label',
            'vtx'
          ),
          prevSlideMessage: _x(
            'Previous slide',
            'carousel previous label',
            'vtx'
          ),
          nextSlideMessage: _x('Next slide', 'carousel next label', 'vtx'),
        },
      };

      if (8 < slides.length) {
        args.loop = true;
      }
      new Swiper(serviceCarousel.querySelector('.swiper'), args);
    });
  }
});
